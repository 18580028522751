<template>
  <div>
  
    <the-modal class="col-lg-8 offset-lg-2" v-show="videoActive">
      <b-icon-x id="close-x" class="d-none d-lg-block" @click="closeEverything" style="position: absolute; color: white; z-index: 150; right: -50px; height: 35px; width: 35px; opacity: .5;"></b-icon-x>
      <video
        class="embed-responsive embed-responsive-16by9 video"
        controls
        ref="indexVideo" 
        src="https://unityworks-a.akamaihd.net/anon.unityworks/2/Z/V/45944358_Honda_Civic_Overview_210506093345_4.mp4"
        type='video/mp4'>
      </video>
    </the-modal>

    <div class="d-flex justify-content-center h-100 content">
      <b-row class="w-100" style="max-width: 1200px;">

        <b-col md="12">
          <h1 class="mt-4" style="font-size: 25px;">{{ 'MY22 Civic Launch Dealer Checklist' | capitalizeAll }}</h1>
          <h2 style="font-size: 20px;">Check all the boxes for a high-impact launch.</h2>
        </b-col>

        <b-col md="8" class="mt-4">
          <div class="pr-4 mr-1 float-left" style="width: 200px;">
            <img
              @click="playVideo()"
              class="img-fluid"
              id="video-thumb"
              src="@/assets/video_thumb_civic.jpg"
            />
            <p class="text-center" style="margin-bottom: 0px;">All New Civic</p>
          </div>
          <p>Featuring a sleek design and a new lineup of body styles, we're excited to release our 2022 Civic into the market.</p>
          <p>Are you ready for the launch? Follow this checklist to set your dealership up for sales success.</p>

          <div :key="section.key" v-for="section in sectionContent">
            <h3 style="font-size: 20px; font-weight: 600; margin-top: 25px;">{{ section.title }}</h3>
            <p>{{ section.description }}</p>
            <div class="checkbox-container">
              <b-row :key="item" v-for="item in section.items">
                <b-col>
                  <b-img
                    fluid
                    src="@/assets/check.png"
                    style="width: 12px;"
                    class="float-left"
                  />
                  <p class="checkbox-text">{{ item }}</p>
                </b-col>
              </b-row>
            </div>
          </div> 
        </b-col>

        <b-col md="4">
          <b-img
            fluid
            src="@/assets/22_civic_sm.jpg"
            style="margin-top:25px;"
            class="photo"
          />
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>

import TheModal from '@/components/TheModal.vue'
import { BIconX } from 'bootstrap-vue'

export default {
  name: 'Civic Launch Content',
  components: {
    TheModal,
    BIconX
  },

  metaInfo: {
    title: 'About'
  },

  data() {
    return {
      sectionContent: {
        0: {
          'title': 'Content Preparation',
          'description': 'There\'s no need to start from scratch. While there are a couple of items below that are dealer-specific requiring your attention, launch content has been created and is available for you:',
          'items': [
            'Brand content downloaded from AdBuilder',
            'Brand content selected for use and distribution across channels',
            'Custom content by dealership agency or in-house has been built',
            'Digital videos on product features',
            'Walk-around videos (if car is in stock, doesn\'t have to be too professional)',
            'Why Buy Elevator Speech copy for BDC/sales and all employees',
            'Competitive Comparison Content',
            'In-stock vehicle photography',
            'Video content on post-COVID dealership processes',
            'Sales team engaged to build out their respective Social Channel outreach',
          ],
        },
        1: {
          'title': 'Website Preparation',
          'description': 'As you know, it\'s essential that your inventory is well-represented in your virtual showroom. Ensure your dealer website is up to par before the launch with the following items:',
          'items': [
            'Website provider notified of launch and received appropriate content',
            'Web banner placed in first or second position',
            'Dedicated webpage built for additional product content/reserve now form',
            'Inventory pages built for new model',
            'VDPs properly pull in vehicle info and highlight product enhancements',
            'VDPs lead with any campaign focus features',
            'Lead providers received any relevant updates to lead specifications',
          ],
        },
        2: {
          'title': 'Digital Advertising',
          'description': 'Draw online interest from potential 2022 Civic owners with effective advertising online. Use this list to establish your dealership as the place to go in the minds of car shoppers:',
          'items': [
            'Digital advertising provider notified of launch and received appropriate content and creative',
            'New keywords to cover all Google "ready to buy" iterations for the new model',
            'VDPs deeplinked appropriately (e.g. "Brand New Civic" leads to 2022 model VRP)',
            'Provider has multiple copy choices for A/B testing and optimization',
            'Lead providers have received any relevant updates to lead specifications',
          ],
        },
        3: {
          'title': 'Social Media',
          'description': 'Connect with shoppers on social media platforms where they are already spending their time. Here are a couple of ways to extend your reach and show off your dealership\'s unique personality:',
          'items': [
            'Social media posts highlight the new vehicle (organically)',
            'Use auto inventory ads to target in-market shoppers for the new model (paid)',
          ],
        },
        4: {
          'title': 'Multicultural',
          'description': 'Make sure you\'re not eliminating potential Civic buyers by ensuring your advertising efforts are all-inclusive. Here are a couple of steps to take:',
          'items': [
            'Create a plan to reach prospects in their preferred spoken language',
            'Drive multicultural to custom in-language landing pages',
          ],
        },
        5: {
          'title': 'SEO',
          'description': 'Increase the volume and quality of your website traffic through search engine optimization. Find new Civic shoppers by taking time to follow through with the steps below:',
          'items': [
            'Add photos & videos of new model inventory to make it discoverable in GMB',
            'Add website links to new model inventory page to increase visibility on the SERPs',
          ],
        },
        6: {
          'title': 'Customer Database Marketing',
          'description': 'Get ready to communicate the value of the 2022 Civic with your existing contacts. Prepare to reach out by creating standardized messaging that\'s ready to go:',
          'items': [
            'Prepare messaging to existing model customers who purchased more than two years ago',
            'Prepare messaging for lessees of previous generation model',
          ],
        },
      },
    };
  },

  watch: {
    $route: "fetchData",
    videoActive: function() {
      if (!this.videoActive) {
        // this.resetSupportForm();
        this.stopVideo();
      }
    }
  },

  methods: {
    playVideo() {
      this.indexVideo.play();
      this.$store.commit("toggleModal");
      this.$store.commit("toggleVideo");
    },

    stopVideo() {
      this.indexVideo.pause();
      this.indexVideo.currentTime = 0;
    },

    closeEverything() {
      this.$store.commit("closeEverything");
    }
  },

  computed: {
    videoActive() {
      return this.$store.state.videoActive ? true : false;
    },

    indexVideo() {
      return this.$refs.indexVideo;
    },
  },
}



</script>

<style scoped>

  #close-x:hover {
    cursor: pointer;
    opacity: 1;
  }

  .photo {
    margin-right: 35px;
    padding: 10px;
    border-radius: 15px;
  }

  .content {
    margin-top: 20px;
  }

  @media screen and (min-width: 1400px) {

    .checkbox-container {
      margin-left: 60px;
      margin-top: 30px;
    }

    .checkbox-container p {
      margin-top: -6px;
      margin-left: 20px;
    }

    .video {
      position: absolute;
      z-index: 250;
      background-color: black;
      border-radius: 5px;
    }

    .video:focus {
      outline: none;
    }

    #video-thumb:hover {
      opacity: 0.75;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1399px) {

    .checkbox-container {
      margin-left: 60px;
      margin-top: 30px;
    }

    .checkbox-container p {
      margin-top: -6px;
      margin-left: 20px;
    }

    .video {
      position: absolute;
      z-index: 250;
      background-color: black;
      border-radius: 5px;
    }

    .video:focus {
      outline: none;
    }

    #video-thumb:hover {
      opacity: 0.75;
      cursor: pointer;
    }

  }

  @media screen and (max-width: 768px) {

    .checkbox-container {
      margin-left: 10px;
      margin-top: 20px;
    }

    .checkbox-container p {
      margin-top: -10px;
      margin-left: 25px;
    }

    .content {
      margin-top: 0px;
      padding-top: 60px;
    }

    .photo {
      margin-right: 0px;
    }

    .video {
      position: fixed;
      right: 0px;
      border-radius: 0px;
      margin-top: 25%;
      z-index: 400;
    }

  }

</style>